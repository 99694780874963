const BarraBrasil = () => (
  <>
    {/* <div id="barra-brasil">
      <ul id="menu-barra-temp">
        <li>
          <a href="http://brasil.gov.br">Portal do Governo Brasileiro</a>
        </li>
      </ul>
    </div> */}

    {/* <div
        dangerouslySetInnerHTML={
          `
    <div id="barra-brasil" style="background:#7F7F7F; height: 20px; padding:0 0 0 10px;display:block;">
    <ul id="menu-barra-temp" style="list-style:none;">
      <li style="display:inline; float:left;padding-right:10px; margin-right:10px; border-right:1px solid #EDEDED">
          <a href="http://brasil.gov.br" style="font-family:sans,sans-serif; text-decoration:none; color:white;">Portal do Governo Brasileiro</a>
      </li>
    </ul>
  </div>
    ` as any
        }
      /> */}
  </>
);

export default BarraBrasil;
